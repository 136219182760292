<template>
  <div id="user-view" class="" v-if="!loading">
    <v-row class="">
      <v-col cols="12" md="5">
        <ProviderBioPanel v-if="provider" :user="provider"></ProviderBioPanel>
        <br />
        <!-- <v-card class="d-flex flex-column justify-center align-center">
          <v-card-text>
            <v-btn
              class=""
              color="secondary"
              block
              outlined
              @click="$router.go(-1)"
            >
              <v-icon>
                {{ icons.mdiArrowLeft }}
              </v-icon>
              <span class="ml-2">Volver</span>
            </v-btn>
          </v-card-text>
        </v-card> -->
      </v-col>

      <v-col cols="12" md="8" class="" v-if="false">
        <v-tabs v-model="userTab" show-arrows class="user-tabs">
          <v-tab>
            <v-img
              :src="$vuetify.theme.isDark ? icons.tripWhite : icons.tripDark"
              class="icon-white"
            ></v-img>
            <span class="ml-2">{{ tabs[0].title }}</span>
          </v-tab>
          <v-tab>
            <v-icon size="20" class="me-3">
              {{ tabs[1].icon }}
            </v-icon>
            <span>{{ tabs[1].title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item class="">
            Tabla paginada con viajes del usuario estadisticas de viaje, precio
            promedio de viaje, distancia recorrida, distancia promedio, top 5 de
            destinos, top 5 de origen cantidad de viajes, cantidad de
            cancelados, viajes x horas, etc etc, view map con toodos los viajes
            pintados del usuario en un mapa</v-tab-item
          >

          <v-tab-item>
            Tabla paginada con movimientos de saldo del usuario
          </v-tab-item>
          <v-tab-item> Notificaciones </v-tab-item>
          <v-tab-item> 4 </v-tab-item>
          <v-tab-item> 5 </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mdiArrowLeft, mdiCurrencyUsd } from "@mdi/js";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";
import ProviderBioPanel from "./parts/ProviderBioPanel.vue";
import tripWhite from "@/assets/Trip_white.svg";
import tripDark from "@/assets/Trip_dark.svg";
export default {
  components: {
    ProviderBioPanel,
  },
  data() {
    return {
      icons: {
        mdiArrowLeft,
        tripWhite,
        tripDark,
        mdiCurrencyUsd,
      },
      trips: {},
      provider: false,
      userTab: null,
      tabs: [
        { icon: mdiArrowLeft, title: "Viajes" },
        { icon: mdiCurrencyUsd, title: "Movimientos" },
      ],
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getProvider() {
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/provider/${this.$route.params.id}`
        );
        if (!!data.success) {
          this.provider = data.user;
          console.log(this.provider);
        } else {
          this.$router.push({
            name: "Error",
          });
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
    },
  },
  computed: {
    ...mapState(["loading"]),
  },
  created() {
    this.setLoading(true);
    this.getProvider();
    this.setLoading(false);
  },
};
</script>
<style lang="scss">
#user-view {
  .user-tabs {
    &.v-tabs:not(.v-tabs--vertical) {
      box-shadow: none !important;
      .v-tabs-bar {
        background-color: transparent !important;
      }
    }
  }

  // tab content
  #user-tabs-content {
    background-color: transparent;
  }
}
</style>
