<template>
  <v-row>
    <v-col cols="12">
      <v-card class="card_">
        <div class="avatar">
          <v-avatar class="avatar-image" color="info" size="110" contain tile>
            <span
              v-if="user.picture"
              class="d-flex align-center justify-center"
            >
              <v-img
                :src="`${bucketUrl}${user.picture}`"
                :max-width="110"
                :alt="user.first_name.charAt(0) + user.last_name.charAt(0)"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row> </template
              ></v-img>
            </span>
            <span v-else class="white--text text-h4">{{ user.first_name !== undefined && user.last_name !== undefined &&
              user.first_name.charAt(0) + user.last_name.charAt(0)
            }}</span>
          </v-avatar>
        </div>
        <v-card-title class="justify-center flex-column mt-12">
          <span
            class="mb-1"
            :class="!$vuetify.theme.isDark && 'color-blue-text'"
            >{{ user.first_name + " " + user.last_name }}</span
          >
          <br />
          <span
            class="mb-1"
            :class="!$vuetify.theme.isDark && 'color-blue-text'"
            >ID: {{ user.unique_id }}</span
          >
        </v-card-title>
        <v-card-text
          class="d-flex flex-column"
          v-if="
            isProviderInRecentTrips &&
            isProviderInRecentTrips.trip_status !== 'Waiting'
          "
        >
          <h2 class="text-xl font-weight-semibold mb-2">{{ $t("Details") }}</h2>
          <v-divider></v-divider>
          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">{{ $t("Email") }}:</span>
              <span class="text--secondary"
                ><a :href="`mailto:${user.email}`">{{ user.email }}</a></span
              >
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2"
                >{{ $t("Telephone") }}:</span
              >
              <span class="text--secondary"
                ><a :href="`tel:${user.country_phone_code}${user.phone}`">{{
                  user.country_phone_code + user.phone
                }}</a></span
              >
              <br />
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2"
                >{{ $t("Trip in couse") }}:</span
              >
              <span class="text--secondary">
                <a
                  :href="`/view-map/${isProviderInRecentTrips._id}`"
                  target="_blank"
                >
                  {{ isProviderInRecentTrips.unique_id }}
                </a></span
              >
              <br />
            </v-list-item>
            <br />
            <v-divider></v-divider>
            <br />
            <v-container
              fluid
              dense
              class="d-flex flex-column px-0 justify-start align-start"
              v-if="isProviderInRecentTrips.trip_status !== 'Waiting'"
            >
              <h2 class="text-xl font-weight-semibold mb-2">
                {{ $t("Vehicle") }}
              </h2>
              <span class="d-flex flex-column" style="width: 100%">
                <br />
                <span class="text--secondary">
                  <span
                    class="d-flex flex-row align-center justify-center"
                    :class="!$vuetify.theme.isDark && 'color-blue-text'"
                  >
                    <span>{{ $t("Brand") }}: <span class="font-weight-bold">{{ computedVehicle.name }}</span></span>
                    <span class="ml-4">
                      {{ $t("Model") }}: <span class="font-weight-bold">{{ computedVehicle.model }}</span>
                    </span>
                    <span class="ml-4">
                      {{ $t("Year") }}: <span class="font-weight-bold">{{ computedVehicle.passing_year }}</span>
                    </span>
                    <span class="ml-4">
                      {{ $t("Plate number") }}: <span class="font-weight-bold">{{ computedVehicle.plate_no }}</span>
                    </span>
                  </span>
                </span>
              </span>
              <br />
            </v-container>
            <v-list-item
              dense
              class="px-0 mb-n2 d-flex flex-row justify-center align-center"
            >
              <v-tooltip
                top
                content-class="bs-tooltip-top arrow"
                :max-width="300"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="d-flex flex-column justify-center align-center"
                  >
                    <v-img
                      :src="`${bucketUrl}${isProviderInRecentTrips.serviceType.type_image_url}`"
                      max-width="200"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row> </template
                    ></v-img>
                    <span class="fontUrbanist font-weight-bold">{{
                      isProviderInRecentTrips.serviceType.typename
                    }}</span>
                  </span>
                </template>
                <span class="d-flex flex-row justify-center align-center">
                  <v-icon
                    x-small
                    :style="
                      !$vuetify.theme.isDark && 'color: #ffffff !important;'
                    "
                  >
                    {{ icons.mdiAccount }}
                  </v-icon>
                  <span class="ml-2">
                    {{ isProviderInRecentTrips.serviceType.max_space }}
                  </span>
                  <span class="ml-2"> | </span>
                  <v-icon
                    x-small
                    class="ml-2"
                    :style="
                      !$vuetify.theme.isDark && 'color: #ffffff !important;'
                    "
                  >
                    {{ icons.mdiBagSuitcase }}
                  </v-icon>
                  <span class="ml-2">
                    {{ isProviderInRecentTrips.serviceType.baggages }}
                  </span>
                </span>
              </v-tooltip>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mdiCheck, mdiClose, mdiAccount, mdiBagSuitcase } from "@mdi/js";
import axios from "@/plugins/axios";

export default {
  props: {
    user: {
      type: [Object, Boolean],
      // required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiCheck,
        mdiClose,
        mdiAccount,
        mdiBagSuitcase,
      },
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
      table: {
        trips: [],
      },
    };
  },
  methods: {
    async getRecentTrips() {
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/recent_trips`, {params: {only_shipments: this.$route.query.only_shipments}}
        );
        if (!!data.success) {
          this.table.trips = [];
          data.trips.forEach((trip) => {
            this.table.trips.push(trip);
          });
        } else {
          throw new Error("Error getting recent trips");
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
    },
  },
  created() {
    this.getRecentTrips();
  },
  computed: {
    isProviderInRecentTrips() {
      let found = this.table.trips.find(
        (trip) => trip.provider_id === this.user._id
      );
      if (found) {
        return found;
      }
      return false
    },
    computedVehicle() {
      let found = this.user.vehicle_detail.find(
        (vehicle) => vehicle.is_selected === true
      );
      return found;
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  background-color: #ef4370;
  min-height: 100px;
  position: relative;
  &-image {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 50%;
    text-align: center;
  }
}
.card_ {
  min-height: 300px;
}
</style>
